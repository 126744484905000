// Generated by ReScript, PLEASE EDIT WITH CARE

import * as LeadModal from "../../../../../styleguide/modals/LeadModal/LeadModal.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function LocalityShowRequestQuote(props) {
  var $$location = props.location;
  return JsxRuntime.jsx(LeadModal.make, {
              title: "Request Quote for " + $$location.name,
              subtitle: "Need Colocation or another service? Our experts will connect you with " + $$location.providerName + " to get you the best deal.",
              buttonTitle: "Submit",
              close: props.onClose,
              leadType: "Colocation"
            });
}

var make = LocalityShowRequestQuote;

export {
  make ,
}
/* LeadModal Not a pure module */
