// extracted by mini-css-extract-plugin
export var address = "LocalityShowLocationTile__address__c_UsU";
export var agreementCheck = "LocalityShowLocationTile__agreementCheck__jLTfq";
export var anchor = "LocalityShowLocationTile__anchor__Dfnr7";
export var badge = "LocalityShowLocationTile__badge__Zl_az";
export var column = "LocalityShowLocationTile__column__UzPHl";
export var details = "LocalityShowLocationTile__details__zvTFP";
export var detailsContainer = "LocalityShowLocationTile__detailsContainer__Z5rYy";
export var flex = "LocalityShowLocationTile__flex__bFgB0";
export var flexColumn = "LocalityShowLocationTile__flexColumn__elW19";
export var gap1 = "LocalityShowLocationTile__gap1__kZDf1";
export var gap2 = "LocalityShowLocationTile__gap2__BEVSo";
export var gap3 = "LocalityShowLocationTile__gap3__MZcSc";
export var gap4 = "LocalityShowLocationTile__gap4__CZadP";
export var gap5 = "LocalityShowLocationTile__gap5__GTld1";
export var image = "LocalityShowLocationTile__image__Dv0zu";
export var listLocation = "LocalityShowLocationTile__listLocation__AMmfx";
export var listLocationSummary = "LocalityShowLocationTile__listLocationSummary__vF0f2";
export var location = "LocalityShowLocationTile__location__eFpfu";
export var locationImage = "LocalityShowLocationTile__locationImage__cMrTq";
export var logo = "LocalityShowLocationTile__logo__XMTto";
export var name = "LocalityShowLocationTile__name__ZEeci";
export var photo = "LocalityShowLocationTile__photo__KCaoO";
export var productsInfo = "LocalityShowLocationTile__productsInfo__s1KYE";
export var provider = "LocalityShowLocationTile__provider__c5OOV";
export var row = "LocalityShowLocationTile__row__lVVzP";
export var sponsored = "LocalityShowLocationTile__sponsored__yipTy";
export var sponsoredLabel = "LocalityShowLocationTile__sponsoredLabel__Fy8JW";