// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_State from "../../../../../../routes/common/Routes_State.res.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocality from "../../../../../locations/common/index/locality/LocationsIndexLocality.res.js";
import * as ProviderLocalityShowStatesScss from "./ProviderLocalityShowStates.scss";

var css = ProviderLocalityShowStatesScss;

function ProviderLocalityShowStates(props) {
  var country = props.country;
  var provider = props.provider;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.localitiesTitle,
                            children: provider.name + ": Top " + country.administrativeDivisionPlural + " with Data Centers"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              Belt_Array.mapWithIndex(props.states.states, (function (index, state) {
                                      return JsxRuntime.jsx(LocationsIndexLocality.make, {
                                                  name: state.name,
                                                  href: Routes_Provider.State.show(provider.slug, country.slug, state.slug),
                                                  totalLocations: state.totalLocations
                                                }, String(index));
                                    })),
                              JsxRuntime.jsx("a", {
                                    children: "View All " + country.administrativeDivisionPlural,
                                    href: Routes_State.index
                                  })
                            ],
                            className: css.localitiesList
                          })
                    ],
                    className: css.localities
                  }),
              className: css.localitiesContainer
            });
}

var make = ProviderLocalityShowStates;

export {
  css ,
  make ,
}
/* css Not a pure module */
