// extracted by mini-css-extract-plugin
export var buttonInactive = "LocalityShowViewButtons__buttonInactive__O0bTo";
export var buttonLeft = "LocalityShowViewButtons__buttonLeft__QOJOU";
export var buttonMiddle = "LocalityShowViewButtons__buttonMiddle__Lalp4";
export var buttonRight = "LocalityShowViewButtons__buttonRight__Q_05K";
export var column = "LocalityShowViewButtons__column__Q9dk1";
export var flex = "LocalityShowViewButtons__flex__IIXyK";
export var flexColumn = "LocalityShowViewButtons__flexColumn___ydI6";
export var gap1 = "LocalityShowViewButtons__gap1__Q0wAX";
export var gap2 = "LocalityShowViewButtons__gap2__zLlf3";
export var gap3 = "LocalityShowViewButtons__gap3__HxXth";
export var gap4 = "LocalityShowViewButtons__gap4__fnJCo";
export var gap5 = "LocalityShowViewButtons__gap5__NwYy4";
export var row = "LocalityShowViewButtons__row__MMJRk";
export var wrapper = "LocalityShowViewButtons__wrapper__KzQUa";