// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../../styleguide/components/Heading/H2.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_City from "../../../../../../routes/common/Routes_City.res.js";
import * as Routes_Provider from "../../../../../../routes/common/Routes_Provider.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsIndexLocality from "../../../../../locations/common/index/locality/LocationsIndexLocality.res.js";
import * as ProviderLocalityShowCitiesScss from "./ProviderLocalityShowCities.scss";

var css = ProviderLocalityShowCitiesScss;

function ProviderLocalityShowCities(props) {
  var countryState = props.countryState;
  var country = props.country;
  var provider = props.provider;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.localitiesTitle,
                            children: provider.name + ": Top Cities with Data Centers"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              Belt_Array.mapWithIndex(props.cities.cities, (function (index, city) {
                                      return JsxRuntime.jsx(LocationsIndexLocality.make, {
                                                  name: city.name,
                                                  href: Routes_Provider.City.show(provider.slug, country.slug, countryState.slug, city.slug),
                                                  totalLocations: city.totalLocations
                                                }, String(index));
                                    })),
                              JsxRuntime.jsx("a", {
                                    children: "View All Cities",
                                    href: Routes_City.index
                                  })
                            ],
                            className: css.localitiesList
                          })
                    ],
                    className: css.localities
                  }),
              className: css.localitiesContainer
            });
}

var make = ProviderLocalityShowCities;

export {
  css ,
  make ,
}
/* css Not a pure module */
