// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as IconOk from "../../../../../styleguide/icons/IconOk.res.js";
import * as Picture from "../../../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../../../models/Location.res.js";
import ReactLazyload from "react-lazyload";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocalityShowRequestQuote from "../request-quote/LocalityShowRequestQuote.res.js";
import * as LocalityShowLocationTileScss from "./LocalityShowLocationTile.scss";

var css = LocalityShowLocationTileScss;

function LocalityShowLocationTile(props) {
  var __style = props.style;
  var __showAgreementCheck = props.showAgreementCheck;
  var __deferImage = props.deferImage;
  var markSponsored = props.markSponsored;
  var $$location = props.location;
  var deferImage = __deferImage !== undefined ? __deferImage : false;
  var showAgreementCheck = __showAgreementCheck !== undefined ? __showAgreementCheck : false;
  var style = __style !== undefined ? __style : "Tile";
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  if (style === "Tile") {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsxs(A.make, {
                      href: $$location.url,
                      targetBlank: true,
                      className: css.anchor,
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Picture.make, {
                                            src: $$location.providerLogo,
                                            large: [
                                              80,
                                              50
                                            ],
                                            crop: "Fit"
                                          }),
                                      className: css.logo
                                    }),
                                $$location.providerAgreement && showAgreementCheck ? JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(IconOk.make, {
                                              size: "XXS",
                                              color: "White"
                                            }),
                                        className: css.agreementCheck
                                      }) : null,
                                deferImage ? JsxRuntime.jsx(ReactLazyload, {
                                        height: 180,
                                        children: JsxRuntime.jsx(Picture.make, {
                                              src: $$location.imageUrl,
                                              large: [
                                                320,
                                                180
                                              ],
                                              small: [
                                                300,
                                                180
                                              ],
                                              className: css.image,
                                              fallbackSrc: $$Location.imageNotFoundUrl
                                            })
                                      }) : JsxRuntime.jsx(Picture.make, {
                                        src: $$location.imageUrl,
                                        large: [
                                          320,
                                          180
                                        ],
                                        className: css.image,
                                        fallbackSrc: $$Location.imageNotFoundUrl
                                      })
                              ],
                              className: css.photo
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: $$location.providerName,
                                              className: css.provider
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: $$location.name,
                                              className: css.name
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: $$location.fullAddress,
                                              className: css.address
                                            })
                                      ],
                                      className: css.details
                                    }),
                                markSponsored ? JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("em", {
                                              children: "SPONSORED"
                                            }),
                                        className: css.sponsoredLabel
                                      }) : null
                              ],
                              className: css.detailsContainer
                            })
                      ]
                    }),
                className: Cx.cx([
                      css.location,
                      markSponsored ? css.sponsored : ""
                    ])
              });
  } else {
    return JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx(A.make, {
                        href: $$location.url,
                        targetBlank: true,
                        className: css.anchor,
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        markSponsored ? JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("em", {
                                                      children: "SPONSORED"
                                                    }),
                                                className: css.sponsoredLabel
                                              }) : null,
                                        JsxRuntime.jsxs("div", {
                                              children: [
                                                JsxRuntime.jsx("div", {
                                                      children: JsxRuntime.jsx(Picture.make, {
                                                            src: $$location.providerLogo,
                                                            large: [
                                                              80,
                                                              50
                                                            ],
                                                            crop: "Fit"
                                                          }),
                                                      className: css.logo
                                                    }),
                                                $$location.providerAgreement && showAgreementCheck ? JsxRuntime.jsx("div", {
                                                        children: JsxRuntime.jsx(IconOk.make, {
                                                              size: "XXS",
                                                              color: "White"
                                                            }),
                                                        className: css.agreementCheck
                                                      }) : null,
                                                deferImage ? JsxRuntime.jsx(ReactLazyload, {
                                                        height: 120,
                                                        children: JsxRuntime.jsx(Picture.make, {
                                                              src: $$location.imageUrl,
                                                              large: [
                                                                160,
                                                                120
                                                              ],
                                                              className: css.image,
                                                              fallbackSrc: $$Location.imageNotFoundUrl
                                                            })
                                                      }) : JsxRuntime.jsx(Picture.make, {
                                                        src: $$location.imageUrl,
                                                        large: [
                                                          160,
                                                          120
                                                        ],
                                                        className: css.image,
                                                        fallbackSrc: $$Location.imageNotFoundUrl
                                                      })
                                              ],
                                              className: css.photo
                                            })
                                      ],
                                      className: css.locationImage
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx("div", {
                                              children: $$location.providerName,
                                              className: css.provider
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: $$location.name,
                                              className: css.name
                                            }),
                                        JsxRuntime.jsx("div", {
                                              children: $$location.fullAddress,
                                              className: css.address
                                            })
                                      ],
                                      className: css.details
                                    })
                              ],
                              className: css.listLocationSummary
                            })
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Button.make, {
                                    size: "MD",
                                    color: "Gray",
                                    className: css.calloutButton,
                                    onClick: (function (param) {
                                        setModalOpen(function (param) {
                                              return true;
                                            });
                                      }),
                                    children: "Request Quote"
                                  }),
                              className: css.availability
                            }),
                        className: css.requestQuote
                      }),
                  match[0] ? JsxRuntime.jsx(LocalityShowRequestQuote.make, {
                          location: $$location,
                          onClose: (function () {
                              setModalOpen(function (param) {
                                    return false;
                                  });
                            })
                        }) : null
                ],
                className: Cx.cx([
                      css.listLocation,
                      markSponsored ? css.sponsored : ""
                    ])
              });
  }
}

var make = LocalityShowLocationTile;

export {
  css ,
  make ,
}
/* css Not a pure module */
