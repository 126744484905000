// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as LocalityShowViewButtonsScss from "./LocalityShowViewButtons.scss";

var css = LocalityShowViewButtonsScss;

function LocalityShowViewButtons(props) {
  var updateView = props.updateView;
  var view = props.view;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Button.make, {
                      size: "SM",
                      color: view === "Grid" ? "DarkerGray" : "White",
                      className: Cx.cx([
                            css.button,
                            css.buttonLeft,
                            view === "Grid" ? "" : css.buttonInactive
                          ]),
                      onClick: (function (param) {
                          if (view !== "Grid") {
                            return updateView("Grid");
                          }
                          
                        }),
                      children: "Grid"
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "SM",
                      color: view === "List" ? "DarkerGray" : "White",
                      className: Cx.cx([
                            css.buttonMiddle,
                            view === "List" ? "" : css.buttonInactive
                          ]),
                      onClick: (function (param) {
                          if (view !== "List") {
                            return updateView("List");
                          }
                          
                        }),
                      children: "List"
                    }),
                JsxRuntime.jsx(Button.make, {
                      size: "SM",
                      color: view === "Map" ? "DarkerGray" : "White",
                      className: Cx.cx([
                            css.buttonRight,
                            view === "Map" ? "" : css.buttonInactive
                          ]),
                      onClick: (function (param) {
                          if (view !== "Map") {
                            return updateView("Map");
                          }
                          
                        }),
                      children: "On Map"
                    })
              ],
              className: css.wrapper
            });
}

var make = LocalityShowViewButtons;

export {
  css ,
  make ,
}
/* css Not a pure module */
